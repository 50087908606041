import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { Template, Page } from 'common/layout';
import { ContentArea } from 'common/contentArea';

const GeneralPage = ({ data }: GeneralPageProps) => {
  const layoutData = data.contentfulPageGeneral;
  const body = layoutData.body;

  return (
    <Template
      metaTitle={layoutData.title}
      metaDescription={layoutData.metaDescription}
    >
      <Page hideContactFooter pageColor={layoutData.pageColor}>
        <ContentArea contentTypes={body} />
      </Page>
    </Template>
  );
};

export default GeneralPage;

export const query = graphql`
  query GeneralPage($id: String) {
    contentfulPageGeneral(id: {eq: $id}) {
      title
      metaDescription
      pageColor
      slug
      body {
        __typename
        ...GeneralPageBodyFragment
      }
    }
  }
`;

export const generalPageBodyFragment = graphql`
  fragment GeneralPageBodyFragment on Node {
    ...RichText
    ...Video
    ...ImageComponent
    ...Events
  }
`;

type GeneralPageProps = {
  data: {
    contentfulPageGeneral: i.GeneralPage;
  };
};
